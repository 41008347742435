import _ from "lodash";
import React from "react";
import VATField from "../VATField";
import validate from "validate.js";
import { i18n } from "../../config";
import Settings from "../../settings";
import { data_create_api_resource } from "../../utils/http_functions";
import { parseJSON, objKeysToCase } from "../../utils/misc";
import RichContent from "../RichContent";
import Phone from "phone";

import { Button, TextField, FormControlLabel, Checkbox, FormHelperText, Typography } from "@material-ui/core"

export default class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      nif: "",
      contract: "",
      email: "",
      email2: "",
      acceptConditions: false,
      acceptGdpr: false,
      phone: "",
      invoiceNumber: "",

      errors: {},
    };

    validate.validators.presence.options = {
      message: `^${i18n.t('common:text.required_field')}`,
    };

    validate.validators.signupMobile = (value, options, key, attributes) => {
      if (value) {
        const spanishFormattedValue = Phone(value, "ESP");
        const foreignFormattedValue = Phone(value);
        if (
          _.isEmpty(spanishFormattedValue) &&
          _.isEmpty(foreignFormattedValue)
        ) {
          return "^" + i18n.t('common:text.contractation_mobile_not_valid');
        } else {
          return null;
        }
      } else {
        return null;
      }
    };

    this.validations = {
      nif: {
        presence: { allowEmpty: false },
      },
      contract: {
        presence: { allowEmpty: false },
      },
      email: {
        presence: { allowEmpty: false },
      },
      email2: {
        presence: { allowEmpty: false },
      },
      acceptConditions: {
        presence: { allowEmpty: false },
        inclusion: {
          within: [true],
          message: i18n.t('common:text.contractation_ov_validation'),
        },
      },
      acceptGdpr: {
        presence: { allowEmpty: false },
        inclusion: {
          within: [true],
          message: i18n.t('common:text.contractation_gdpr_validation'),
        },
      },
      phone: (value, attributes, attributeName, options, constraints) => {
        if (_.get(Settings, "signUp.phoneField", false)) {
          return {
            presence: { allowEmpty: false },
            signupMobile: true,
          };
        } else {
          return null;
        }
      },
      invoiceNumber: (
        value,
        attributes,
        attributeName,
        options,
        constraints
      ) => {
        if (_.get(Settings, "signUp.invoiceNumberField", false)) {
          return {
            presence: { allowEmpty: false },
          };
        } else {
          return null;
        }
      },
    };
  }

  validate = (attrs = null, validations) => {
    let onlyValidate = {};
    const _validations = validations || this.validations;
    if (attrs) {
      Object.keys(attrs).forEach(
        (key) => (onlyValidate[key] = _validations[key])
      );
    } else {
      onlyValidate = Object.assign({}, _validations);
    }
    const errors = validate(attrs || this.state, onlyValidate) || {};
    
    this.setState({ errors });
    return errors;
  };

  handleGenericChange = (event) => {
    console.log("event", event.target.name);
    const key = event.target.name;
    const value = event.target.value;

    this.setState({ [key]: value }, () => {
      this.validate({ [key]: value });
    });
  };

  handleCheck = (event, checked) => {
    const change = {};
    change[event.target.name] = checked;
    this.setState(change);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { state } = this;

    this.setState((prevState) => {
      return {
        nif: prevState.nif.replace('ES', '')
      }
    })

    const errors = this.validate(state);
    if (_.isEmpty(errors)) {
      console.log("No validation errors!");
      const data = {
        FormConfirm: {
          nif: state.nif,
          contract: state.contract,
          email: state.email,
          email2: state.email2,
          accept_conditions: state.acceptConditions,
          accept_gdpr: state.acceptGdpr,
        },
      };

      if (_.get(Settings, "signUp.phoneField", false)) {
        data.FormConfirm["phone"] = state.phone;
      }

      if (_.get(Settings, "signUp.invoiceNumberField", false)) {
        data.FormConfirm["invoice_number"] = state.invoiceNumber;
      }

      data_create_api_resource(null, "signup/confirm", data)
        .then(parseJSON)
        .then((response) => {
          const r = response.result;
          if (r.render && !_.isEmpty(r.render.errors)) {
            console.log("Backend validation errors", r.render.errors);
            this.setState({
              errors: objKeysToCase(r.render.errors, "camel", "FormConfirm."),
            });
          } else {
            console.log("Submit successful.");
            this.setState({
              submitSuccessful: true,
              successBody: r.render.body,
            });
          }
        });
    } else {
      console.log("Validation errors", errors);
    }
  };

  render() {
    const { errors } = this.state;

    return !this.state.submitSuccessful ? (
      <div>
        <Typography variant="subtitle1">{i18n.t('common:text.signup_all_fields_are_required')}</Typography>
        <div>
          <VATField
            name="nif"
            value={this.state.nif}
            onBlur={(event) => this.handleGenericChange(event)}
            onChange={this.handleGenericChange}
            error={errors.nif}
            showError={!!(errors.nif)}
            style={{ width: "100%" }}
          />
        </div>
        <div>
          <TextField
            label={i18n.t('common:text.signup_field_contract_number')}
            name="contract"
            value={this.state.contract}
            onChange={this.handleGenericChange}
            error={!!(errors.contract)}
            style={{ width: "100%" }}
          />
          {!!(errors.contract) &&
            <FormHelperText error={true}>
              {errors.contract}
            </FormHelperText>
          }
        </div>
        {_.get(Settings, "signUp.invoiceNumberField", false) && (
          <div>
            <TextField
              label={i18n.t('common:text.signup_field_invoice_number')}
              name="invoiceNumber"
              value={this.state.invoiceNumber}
              onChange={this.handleGenericChange}
              error={!!(errors.invoiceNumber)}
              style={{ width: "100%" }}
            />
            {!!(errors.invoiceNumber) &&
              <FormHelperText error={true}>
                {errors.invoiceNumber}
              </FormHelperText>
            }
          </div>
        )}
        <div>
          <TextField
            label={i18n.t('common:text.signup_field_email')}
            name="email"
            value={this.state.email}
            onChange={this.handleGenericChange}
            error={!!(errors.email)}
            style={{ width: "100%" }}
          />
          {!!(errors.email) &&
            <FormHelperText error={true}>
              {errors.email}
            </FormHelperText>
          }
        </div>
        <div>
          <TextField
            label={i18n.t('common:text.signup_field_email_repeat')}
            name="email2"
            value={this.state.email2}
            onChange={this.handleGenericChange}
            error={!!(errors.email2)}
            style={{ width: "100%" }}
          />
          {!!(errors.email2) &&
            <FormHelperText error={true}>
              {errors.email2}
            </FormHelperText>
          }
        </div>
        {_.get(Settings, "signUp.phoneField", false) && (
          <div>
            <TextField
              label={i18n.t('common:text.signup_field_phone')}
              name="phone"
              value={this.state.phone}
              onChange={this.handleGenericChange}
              error={!!(errors.phone)}
              style={{ width: "100%" }}
            />
            {!!(errors.phone) &&
              <FormHelperText error={true}>
                {errors.phone}
              </FormHelperText>
            }
          </div>
        )}
        <div style={{marginTop: 30}}>
          <FormControlLabel
            control={
              <Checkbox
                name="acceptConditions"
                onChange={this.handleCheck}
                checked={this.state.acceptConditions}
                style={{ flex: 2 }}
              />
            }
            label={i18n.t('common:text.signup_field_accept_terms_conditions_ov')}
          />
          <div>
            <a
              style={{ marginTop: 17 }}
              target="_blank"
              href={Settings.links.conditions}
              rel="noreferrer"
            >
              {i18n.t('common:text.contractation_ov_conditions')}
            </a>
          </div>
          {errors && errors.acceptConditions && (
            <div style={{ color: "#f00"}}>
              {errors.acceptConditions}
            </div>
          )}
        </div>
        <div style={{ }}>
          <FormControlLabel
            control={
              <Checkbox
                name="acceptGdpr"
                onChange={this.handleCheck}
                checked={this.state.acceptGdpr}
                style={{ flex: 2 }}
              />
            }
            label={i18n.t('common:text.signup_field_accept_gdpr')}
          />
          <div>
            <a
              style={{ marginTop: 17 }}
              target="_blank"
              href={Settings.links.gdpr}
              rel="noreferrer"
            >
              {i18n.t('common:text.contractation_privacy_policy')}
            </a>
          </div>
          {errors && errors.acceptGdpr && (
            <div style={{ color: "#f00"}}>
              {errors.acceptGdpr}
            </div>
          )}
        </div>
        <Button
          color={'primary'}
          variant={'contained'}
          className="primary-btn submit-btn"
          onClick={this.handleSubmit}
          style={{ marginTop: 30 }}
        >
          {i18n.t('common:text.signup_button_activate')}
        </Button>
      </div>
    ) : (
      <div>
        <RichContent content={this.state.successBody || ""} />
      </div>
    );
  }
}
